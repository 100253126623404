import { useEffect, useState } from 'react'
import { FC, invalidateQuery, useCQuery } from '../../Services'

export const useLineaOccupata = (lineaOccupataId) => {
  const [lineaOccupata, setLineaOccupata] = useState()
  const { data: objLineaOccupata, isSuccess } = useCQuery(['lineaOccupata', lineaOccupataId])

  useEffect(() => {
    isSuccess && setLineaOccupata(objLineaOccupata)
  }, [isSuccess, objLineaOccupata])

  const setLineaOccupataProp = (prop, value) =>
    setLineaOccupata({ ...lineaOccupata, [prop]: value })

  return [{
    ...lineaOccupata,
    startDate: lineaOccupata?.startDate || new Date().setSeconds(0, 0),
    endDate: lineaOccupata?.endDate || new Date().setSeconds(0, 0)
  }, setLineaOccupataProp, isSuccess]
}

export const saveLineaOccupata = async (lineaOccupata) => {
  // MANCA IL CONTROLLO SU reason che però è un elenco che ci deve dare mauro bolla
  if (!lineaOccupata.startDate || !lineaOccupata.endDate || !lineaOccupata.environmentId) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Inserire campi obbligatori' })
    return false
  }
  if (lineaOccupata.id === 'new') {
    delete lineaOccupata.id
    try {
      const res = await FC.service('lineaOccupata').create({ ...lineaOccupata })
      invalidateQuery(['lineaOccupata', 'lineaOccupata'])
      window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
      return res
    } catch (e) {
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore Salvataggio' })
      return false
    }
  }
  try {
    const res = await FC.service('lineaOccupata').patch(lineaOccupata.id, lineaOccupata)
    invalidateQuery(['lineaOccupata', 'lineaOccupata'])
    window.growl.show({ severity: 'success', summary: 'Success', detail: 'Salvataggio effettuato' })
    return res
  } catch (e) {
    window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Errore Salvataggio' })
    return false
  }
}

export const deleteLineaOccupata = async (lineaOccupataId) => {
  const res = await FC.service('lineaOccupata').remove(lineaOccupataId)
  invalidateQuery(['lineaOccupataList', 'lineaOccupata'])
  window.growl.show({ severity: 'success', summary: 'Success', detail: 'Eliminazione effettuata' })
  return res
}

export const calculateDifference = (date1, date2) => {
  // Converti le date in millisecondi
  const diffInMs = Math.abs(new Date(date2) - new Date(date1))

  // Calcola la differenza in ore e minuti
  const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60))
  const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60))

  // Formatta le ore e i minuti con due cifre
  const formattedHours = String(diffInHours)?.padStart(2, '0')
  const formattedMinutes = String(diffInMinutes)?.padStart(2, '0')

  // Restituisce la stringa nel formato hh:mm
  return formattedHours + ':' + formattedMinutes
}
