import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import { FC } from '.'
import { queryClient } from '../App'
import { getDateObj } from './Functions'
import { useEffect } from 'react'

const time = {
  seconds: 1000,
  minutes: 60 * 1000,
  hours: 60 * 60 * 1000
}

const queryConfig = {
  rare: {
    staleTime: 1 * time.hours,
    cacheTime: 2 * time.hours
  },
  frequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes
  },
  autoFetchFrequent: {
    staleTime: 5 * time.minutes,
    cacheTime: 10 * time.minutes,
    refetchInterval: 10 * time.seconds
  }
}

const queryNotFound = {
  config: {},
  queryFn: (key) => { throw new Error('Query Not Found: ' + key) }
}

const DAY_MS = 24 * 60 * 60 * 1000

const queries = {
  environments: {
    config: queryConfig.rare,
    queryFn: () => FC.service('environments').find({})
  },
  environment: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return { id: 'new' }
      const res = await FC.service('environments').get(id)
      return res
    }
  },
  clients: {
    config: queryConfig.rare,
    queryFn: () => FC.service('clients').find({ query: { includeEnvironments: true } })
  },
  client: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return { id: 'new' }
      const [res] = await FC.service('clients').find({ query: { id, includeEnvironments: true } })
      return res
    }
  },
  users: {
    config: queryConfig.rare,
    queryFn: () => FC.service('users').find({})
  },
  user: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return { id: 'new' }
      const res = await FC.service('users').get(id)
      return res
    }
  },
  attendance: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return { id: 'new' }
      const res = await FC.service('attendances').get(id)
      return res
    }
  },
  lineaOccupata: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return { id: 'new' }
      const res = await FC.service('lineaOccupata').get(id, { query: { includeEnvironments: true } })
      return res
    }
  },
  travellers: {
    config: queryConfig.rare,
    queryFn: () => FC.service('travellers').find({})
  },
  traveller: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') {
        return { id: 'new' }
      }
      const res = await FC.service('travellers').get(id)
      return res
    }
  },
  whatsappPhoneNumbers: {
    config: queryConfig.rare,
    queryFn: () => FC.service('whatsappPhoneNumbers').find({})
  },
  formFields: {
    config: queryConfig.rare,
    queryFn: () => FC.service('info').get('formFields')
  },
  templateList: {
    config: queryConfig.rare,
    queryFn: () => FC.service('info').get('getTemplateList')
  },
  prompt: {
    config: queryConfig.rare,
    queryFn: async ({ queryKey: [key, id] }) => {
      if (!id || id === 'new') return null
      const res = await FC.service('prompts').get(id)
      return res
    }
  },
  processes: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, selectedDate] }) => selectedDate ? FC.service('process').find({ query: { $and: [{ createdAt: { $gte: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0, 0) } }, { createdAt: { $lte: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 59, 59, 999) } }] } }) : []
  },
  getStats: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, startDate, endDate] }) => {
      if (!startDate || !endDate) return []
      return FC.service('info').get('getStats', { query: { startDate: getDateObj(startDate), endDate: getDateObj(endDate) } })
    }
  },
  pricingPerProcess: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, processId] }) => {
      if (!processId) return []
      return FC.service('info').get('pricingPerProcess', { query: { processId } })
    }
  },
  pricingPerConversation: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, conversationId] }) => {
      if (!conversationId) return []
      return FC.service('info').get('pricingPerConversation', { query: { conversationId } })
    }
  },
  processSteps: {
    config: queryConfig.autoFetchFrequent,
    queryFn: ({ queryKey: [key, processId] }) => processId ? FC.service('processStep').find({ query: { processId } }) : []
  },
  errorProcessStepsRange: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, startDate, endDate] }) => (startDate && endDate)
      ? FC.service('processStep').find({ query: { $or: [{ stepState: 5 }, { stepState: 6 }], $and: [{ createdAt: { $gte: new Date(startDate).getTime() } }, { createdAt: { $lte: new Date(endDate).getTime() + DAY_MS } }] } })
      : []
  },
  time: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, conversationId] }) => FC.service('time').find({ query: { conversationId, includeUsers: true } })
  },
  timeSearch: {
    config: queryConfig.rare,
    queryFn: ({ queryKey: [key, userId, range] }) => FC.service('time').find({
      query: {
        ...(userId && { userId }),
        $or: [
          {
            endAt: { $gte: range[0], $lte: range[1] },
            createdAt: { $gte: range[0], $lte: range[1] }
          }
        ]
      }
    })
  },
  conversation: {
    config: {
      ...queryConfig.frequent,
      retry: (failureCount, error) => {
        if (error?.toString()?.includes('Conversation not found')) return false
        if (failureCount < 3) return true
      }
    },
    queryFn: ({ queryKey: [key, conversationId] }) => conversationId ? FC.service('info').get('getConversationOperatore', { query: { conversationId } }) : null
  },
  conversationProcess: {
    config: {
      ...queryConfig.frequent,
      retry: (failureCount, error) => {
        if (error?.toString()?.includes('Conversation not found')) return false
        if (failureCount < 3) return true
      }
    },
    queryFn: ({ queryKey: [key, conversationId] }) => conversationId ? FC.service('info').get('getConversationProcess', { query: { conversationId } }) : null
  },
  adminCoefficient: {
    config: queryConfig.rare,
    queryFn: () => FC.service('adminCoefficient').get(1)
  },
  messagesToRead: {
    config: queryConfig.rare,
    queryFn: () => FC.service('info').get('getMessagesToRead')
  }
}

export const useCQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config } = { ...queries, ...extraQueries }?.[queryKey[0]] || queryNotFound
  return useQuery({ queryKey, queryFn, ...config })
}

export const prefetchQuery = (queryKey, extraQueries = []) => {
  const { queryFn, config } = { ...queries, ...extraQueries }[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  queryClient.prefetchQuery({ queryKey, queryFn, staleTime: config?.staleTime || 300000 })
}

export const prefetchInfiniteQuery = (queryKey, extraQueries = []) => {
  const { queryFn, firstPageLength = 50, pageLength = 50 } = { ...infiniteQueries, ...extraQueries }?.[Array.isArray(queryKey) ? queryKey[0] : queryKey] || queryNotFound
  queryClient.prefetchInfiniteQuery({ queryKey, queryFn: queryFn(firstPageLength, pageLength), initialPageParam: 1, getNextPageParam: () => null })
}

/* export const prefetchQueries = (extraQueries) =>
  Object.entries({ ...queries, ...extraQueries }).forEach(([queryKey, { config, queryFn, defaultKeys = [], noInitalPrefetch }]) => {
    const key = [queryKey, ...defaultKeys]
    !noInitalPrefetch && queryClient.prefetchQuery(key, queryFn, config)
  }) */

export const invalidateQuery = (queryKeys) =>
  queryKeys?.forEach(queryKey => {
    const queryKeyToInvalidate = Array.isArray(queryKey) ? queryKey : [queryKey]
    queryClient.invalidateQueries({ queryKey: queryKeyToInvalidate })
    setTimeout(() => {
      if (queryKeyToInvalidate[0] === 'conversations' &&
        queryClient.getQueryCache().findAll()
          .find(query => query.queryKey[0] === queryKeyToInvalidate[0] && (query.state.isInvalidated === false))) {
        console.log('Query not invalidated properly:', queryKeyToInvalidate)
      }
    }, 500)
  })

// FETCH QUERY

/* export const fetchQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config } = { ...queries, ...extraQueries }?.[queryKey[0]] || queryNotFound
  return queryClient.fetchQuery({ queryKey, queryFn, ...config })
} */

// INFINITE QUERIES
const infiniteQueries = {
  conversations: {
    firstPageLength: 20,
    pageLength: 20,
    config: {
      ...queryConfig.frequent,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1, queryKey: [, assignedFilter, selectedFilter, hiddenFilter, range, clientId, hasMessages = false] = [] }) =>
      FC.client.service('info').get('getConversationsOperatore', {
        query: {
          $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
          $limit: pageParam === 1 ? firstPageLength : pageLength,
          assignedFilter,
          conversationFilter: selectedFilter,
          hiddenFilter,
          range,
          clientId,
          hasMessages
        }
      }),
    noInitalPrefetch: true
  },
  lineaOccupataList: {
    firstPageLength: 20,
    pageLength: 20,
    config: {
      ...queryConfig.frequent,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1, queryKey: [, datesSearch] = [] }) => FC.client.service('lineaOccupata').find({
      query: {
        $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
        $limit: pageParam === 1 ? firstPageLength : pageLength,
        $sort: { startDate: 1 },
        includeEnvironments: true,
        includeUsers: true,
        startDate: { $gte: new Date(datesSearch[0].getFullYear(), datesSearch[0].getMonth(), datesSearch[0].getDate(), 0, 0, 0, 0), $lte: new Date(datesSearch[1].getFullYear(), datesSearch[1].getMonth(), datesSearch[1].getDate(), 23, 59, 59, 999) }
      }
    }),
    noInitalPrefetch: true
  },
  attendances: {
    firstPageLength: 20,
    pageLength: 20,
    config: {
      ...queryConfig.frequent,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1, queryKey: [, datesSearch] = [] }) => FC.client.service('attendances').find({
      query: {
        $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
        $limit: pageParam === 1 ? firstPageLength : pageLength,
        $sort: { startDate: 1 },
        includeUsers: true,
        startDate: { $gte: new Date(datesSearch[0].getFullYear(), datesSearch[0].getMonth(), datesSearch[0].getDate(), 0, 0, 0, 0), $lte: new Date(datesSearch[1].getFullYear(), datesSearch[1].getMonth(), datesSearch[1].getDate(), 23, 59, 59, 999) }
      }
    }),
    noInitalPrefetch: true
  },
  conversationsStats: {
    firstPageLength: 10,
    pageLength: 10,
    config: {
      ...queryConfig.frequent,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1, queryKey: [, dateRange] = [] }) => FC.client.service('info').get('getConversationsStats', {
      query: {
        $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
        $limit: pageParam === 1 ? firstPageLength : pageLength,
        createdAt: { $gte: dateRange[0], $lte: dateRange[1] }
      }
    }),
    noInitalPrefetch: true
  },
  usersStats: {
    firstPageLength: 10,
    pageLength: 10,
    config: {
      ...queryConfig.frequent,
      keepPreviousData: true
    },
    queryFn: (firstPageLength, pageLength) => ({ pageParam = 1, queryKey: [, dateRange] = [] }) => FC.client.service('info').get('getUsersStats', {
      query: {
        $skip: (pageParam === 1 ? 0 : ((pageParam - 2) * pageLength + firstPageLength)),
        $limit: pageParam === 1 ? firstPageLength : pageLength,
        createdAt: { $gte: dateRange[0], $lte: dateRange[1] }
      }
    }),
    noInitalPrefetch: true
  }
}

export const useCInfiniteQuery = (queryArgs, extraQueries = []) => {
  const queryKey = Array.isArray(queryArgs) ? queryArgs : [queryArgs]
  const { queryFn, config, firstPageLength = 50, pageLength = 50 } = { ...infiniteQueries, ...extraQueries }?.[queryKey[0]] || queryNotFound

  const { data = {}, isSuccess, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage, isStale } = useInfiniteQuery({
    queryKey,
    queryFn: queryFn(firstPageLength, pageLength),
    // devo fetchare nuova pagina se sono della lunghezza massima stabilita (firstPageLength o pageLength)
    getNextPageParam: (lastPage, pages) =>
      (pages?.length === 1)
        ? ((Array.isArray(lastPage) ? lastPage?.length : lastPage?.data?.length) === firstPageLength) ? pages?.length + 1 : null
        : ((Array.isArray(lastPage) ? lastPage?.length : lastPage?.data?.length) === pageLength) ? pages?.length + 1 : null,
    ...config
  })

  // controllo se la pagina è un array o un oggetto con data e ritorno un array di pagine.
  // perchè se è definita la paginazione sul server ritorna un oggetto con data e se non è definita ritorna un array
  const pages = data?.pages?.map(page => Array.isArray(page) ? page : page.data).flat()

  useEffect(() => { !isFetching && !isFetchingNextPage && hasNextPage && fetchNextPage() }, [isFetchingNextPage, isFetching])

  return { data: pages, isSuccess, isFetchingNextPage, isStale }
}
