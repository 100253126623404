import { useNavigate, useParams } from 'react-router-dom'
import { Button, Flex, Spinner, Text } from '../../Components'
import { useState } from 'react'
import { saveLineaOccupata, useLineaOccupata } from './Functions'
import { invalidateQuery, useCQuery } from '../../Services/QueryCache'
import { FloatInput } from '../Gigi/GigiForm/TransferField/InputForm'
import elencoMotivazioni from '../../Resources/codiciMotivazione.json'
import { InputTime, InputTimeDate } from '../Attendances'

export function LineaOccupata () {
  const { lineaOccupataId } = useParams()
  const navigate = useNavigate()
  const [lineaOccupata, setLineaOccupata, isSuccess] = useLineaOccupata(lineaOccupataId)
  const { id = '' } = lineaOccupata || {}
  const [checkError, setCheckError] = useState(false)
  const { data: agencies = [], isSuccess: isSuccessAgencies } = useCQuery(['environments'])

  const save = async () => {
    setCheckError(true)
    if (!lineaOccupata.startDate || !lineaOccupata.endDate || !lineaOccupata.environmentId) {
      // message e return per uscire dalla funzione
      window.growl.show({ severity: 'error', summary: 'Errore', detail: 'Compila i campi mancanti' })
      return
    }
    setCheckError(false)

    const elaboratedLineaOccupata = { ...lineaOccupata }
    elaboratedLineaOccupata.startDate = new Date(lineaOccupata.startDate)
    elaboratedLineaOccupata.endDate = new Date(lineaOccupata.endDate)
    elaboratedLineaOccupata?.endDate?.setDate(elaboratedLineaOccupata.startDate?.getDate())
    elaboratedLineaOccupata?.endDate?.setMonth(elaboratedLineaOccupata.startDate?.getMonth())
    elaboratedLineaOccupata?.endDate?.setFullYear(elaboratedLineaOccupata.startDate?.getFullYear())
    // se l'orario di fine è inferiore a quello di inizio, setto la data di fine al giorno successivo, altrimenti setto la data di fine uguale a quella di inizio
    if (new Date(lineaOccupata.endDate).getHours() < new Date(lineaOccupata.startDate).getHours() || (new Date(lineaOccupata.endDate).getHours() === new Date(lineaOccupata.startDate).getHours() && new Date(lineaOccupata.endDate).getMinutes() < new Date(lineaOccupata.startDate).getMinutes())) {
      const endDate = new Date(lineaOccupata.endDate)
      endDate.setDate(new Date(lineaOccupata.startDate).getDate() + 1)
      endDate.setMonth(new Date(lineaOccupata.startDate).getMonth())
      endDate.setFullYear(new Date(lineaOccupata.startDate).getFullYear())
      elaboratedLineaOccupata.endDate = endDate
    } else {
      const endDate = new Date(lineaOccupata.endDate)
      endDate.setDate(new Date(lineaOccupata.startDate).getDate())
      endDate.setMonth(new Date(lineaOccupata.startDate).getMonth())
      endDate.setFullYear(new Date(lineaOccupata.startDate).getFullYear())
      elaboratedLineaOccupata.endDate = endDate
    }
    const res = await saveLineaOccupata(elaboratedLineaOccupata)
    if (!res) return
    invalidateQuery(['lineaOccupata', 'lineaOccupataList'])
    res?.id && navigate('/lineaoccupata/list')
    return res
  }

  const isNew = id === 'new'
  if (!isSuccess || !lineaOccupata || !isSuccessAgencies) return <Spinner />
  return (
    <Flex fw>
      <Text value={`${isNew ? 'Inserisci' : 'Modifica'} Attività`} title bold size={30} />
      <Flex js fw row wrap style={{ marginTop: 20 }}>
        <Flex style={{ width: '49%', marginRight: '1%' }}>
          <InputTimeDate
            value={[new Date(lineaOccupata.startDate), (date) => setLineaOccupata('startDate', date)]}
            error={checkError && !lineaOccupata.startDate}
            label='Data Inizio' labelTime='Ora Inizio'
          />
        </Flex>
        <Flex style={{ width: '49%', marginLeft: '1%' }}>
          <InputTime
            value={[new Date(lineaOccupata.endDate), (date) => setLineaOccupata('endDate', date)]}
            error={checkError && !lineaOccupata.endDate}
            label='Data Fine' labelTime='Ora Fine'
          />
        </Flex>
        <Flex style={{ width: '49%', marginRight: '1%' }}>
          <FloatInput
            type='dropdown' error={checkError && !lineaOccupata.reason} filter
            value={[lineaOccupata?.reason, (value) => setLineaOccupata('reason', value)]}
            options={elencoMotivazioni} label='Motivazione' id='reason'
          />
        </Flex>
        <Flex style={{ width: '49%', marginLeft: '1%' }}>
          <FloatInput
            type='dropdown' error={checkError && !lineaOccupata?.environmentId} filter
            value={[lineaOccupata.environmentId, (value) => setLineaOccupata('environmentId', value)]}
            options={agencies?.map(({ id, agencyName, codClienteNerone }) => ({ label: agencyName + ' - ' + codClienteNerone, value: id }))}
            label='Ragione Sociale Agenzia' id='agencyName'
          />
        </Flex>
        <FloatInput
          value={[lineaOccupata?.description || '', (data) => setLineaOccupata('description', data)]}
          label='Descrizione attività' id='description' type='multiline'
          style={{ width: '98%', marginLeft: '1%', marginRight: '1%' }}
        />
      </Flex>
      <Flex fw row style={{ marginTop: 20 }}>
        <Button label='Salva' icon='fa-duotone fa-check' style={{ marginRight: 10 }} onClick={save} />
        <Button label='Torna indietro' icon='fa-duotone fa-arrow-left' onClick={() => navigate('/lineaoccupata/list')} />
      </Flex>
    </Flex>
  )
}
