import React, { useState, useEffect } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { Text } from '../../Components/Common/Text'
import { useCQuery } from '../../Services/QueryCache'
import { columnProps } from '../../Services/Functions'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { formatMilliseconds } from './Functions'

export const TimeView = ({ conversationId }) => {
  const { data = [], isSuccess } = useCQuery(['time', conversationId])
  const [timeSum, setTimeSum] = useState(0)
  const [timeList, setTimeList] = useState([])

  useEffect(() => {
    if (!data?.length) return
    setTimeSum(data.reduce((acc, curr) => acc + curr.time, 0))
    setTimeList(data?.map((time) => ({
      ...time,
      user: time?.user?.username + ((time?.user?.name || time?.user?.surname) ? (' - ' + time?.user?.name + ' ' + time?.user?.surname) : '')
    })))
  }, [data?.length])

  if (!isSuccess) return null
  return (
    <Flex fw>
      <Text value='Resoconto tempo' bold title upCase size={22} style={{ marginBottom: 10 }} />
      <Flex row jb style={{ marginBottom: 10 }}>
        <Text value='Totale:' bold size={18} style={{ marginRight: 10 }} />
        <Text value={formatMilliseconds(timeSum)} size={18} />
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' className='TableSignatures'
        value={timeList} emptyMessage='Nessuna attività presente' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='user' {...columnProps('Utente')} />
        <Column field='createdAt' {...columnProps('Data Inizio')} body={(rowData) => new Date(rowData.createdAt).toLocaleString('it-IT')} />
        <Column field='time' {...columnProps('Tempo')} body={(rowData) => formatMilliseconds(rowData.time)} />
      </DataTable>
    </Flex>
  )
}
