import React, { useState, useEffect } from 'react'
import { Flex } from '../../Components/Common/Flex'
import { DataTable } from 'primereact/datatable'
import { useCQuery } from '../../Services/QueryCache'
import { columnProps } from '../../Services/Functions'
import { Column } from 'primereact/column'
import { Calendar } from 'primereact/calendar'
import { FloatInput } from '../Gigi/GigiForm/TransferField/InputForm'
import { formatMilliseconds } from '../StatisticheOperatori/Functions'
import { Button } from '../../Components/Common/Button'

export const TimeSearch = () => {
  const [range, setRange] = useState([new Date(new Date().setHours(0, 0, 0, 0)), new Date()])
  const [datesSearch, setDatesSearch] = useState(range)
  const [selectedUser, setSelectedUser] = useState(false)
  const { data: time = [] } = useCQuery(['timeSearch', selectedUser, datesSearch])
  const { data: users = [] } = useCQuery(['users'])
  const [timeList, setTimeList] = useState([])

  useEffect(() => {
    setTimeList(time?.map((time) => ({
      ...time,
      user: users.find(({ id }) => id === time.userId)?.username,
      time: formatMilliseconds(time?.time),
      createdAt: new Date(time.createdAt).toLocaleString('it-IT'),
      endAt: new Date(time.endAt).toLocaleString('it-IT')
    })))
  }, [time?.length])

  return (
    <Flex fw>
      <Flex fw row style={{ marginBottom: 20 }}>
        <Flex style={{ width: 350, marginRight: 20 }}>
          <FloatInput
            id='user' value={[selectedUser, setSelectedUser]}
            type='dropdown'
            options={[{ label: 'Tutti', value: false }, ...users.map(({ id, username }) => ({ label: username, value: id }))]}
            label='Seleziona utente'
            noMarginTop
          />
        </Flex>
        <Calendar
          showTime
          value={datesSearch} selectionMode='range' onChange={(e) => {
            setDatesSearch(e.value)
            e.value?.filter(Boolean).length === 2 && setRange(e.value)
          }}
          locale='it' dateFormat='dd/mm/yy' style={{ width: 350 }}
        />
      </Flex>
      <DataTable
        style={{ width: '100%' }} filterDisplay='row' className='TableSignatures'
        value={timeList} emptyMessage='Nessuna attività presente' responsiveLayout='scroll' paginator rows={10}
      >
        <Column field='user' {...columnProps('Utente')} />
        <Column field='createdAt' {...columnProps('Inizio')} />
        <Column field='endAt' {...columnProps('Fine')} />
        <Column field='time' {...columnProps('Tempo')} />
        <Column
          style={{ width: 150 }}
          field='conversationId' body={(rowData) =>
            <Button
              icon='fa-duotone fa-external-link' label={'Id: ' + rowData.conversationId} style={{ width: 150 }}
              onClick={() => window.open(window.location.origin + '/operatore/' + rowData.conversationId, '_blank')}
            />}
        />
      </DataTable>
    </Flex>
  )
}
